<template>
  <div style="margin-bottom: 20px">
    <el-form :inline="true" :model="queryForm">
      <el-form-item label="统计时间：" style="width: 440px">
        <el-date-picker
          v-model="statisticsDate"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="选择部门：" style="width: 350px">
        <el-cascader
          v-model="deptTreeid"
          :options="deptTree"
          @change="deptChange"
          :props="{
            multiple: true,
            emitPath: false,
            checkStrictly: true,
            children: 'childList',
            label: 'orgName',
            value: 'id'
          }"
          collapse-tags
          clearable
          filterable
          style="width: 250px"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="课程顾问：">
        <el-select
          v-model="personIds"
          filterable
          @change="getAssetsList"
          multiple
          collapse-tags
          placeholder="请选择"
          clearable
        >
          <div class="select-btn">
            <el-button
              type="text"
              size="mini"
              @click="handleAllCheck(0)"
              v-if="!isAllPerson"
            >
              全选
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="cancelAllCheck(0)"
              v-if="isAllPerson"
            >
              取消全选
            </el-button>
          </div>

          <el-option
            v-for="(item, index) in consultantList"
            :key="index"
            :label="item.personRealName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择账号：">
        <el-select
          v-model="assetsIds"
          filterable
          multiple
          collapse-tags
          placeholder="请选择"
          @change="changeAssets"
        >
          <div class="select-btn">
            <el-button
              type="text"
              size="mini"
              @click="handleAllCheck(1)"
              v-if="!isAllAssets"
            >
              全选
            </el-button>
            <el-button
              type="text"
              size="mini"
              @click="cancelAllCheck(1)"
              v-if="isAllAssets"
            >
              取消全选
            </el-button>
          </div>
          <el-option
            v-for="(item, index) in accountsList"
            :key="index"
            :label="item.wechatNum"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="emitData" type="primary">查询</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getDeptTree } from '@/api/organization'
import { queryConsultantList, queryAssets } from '@/api/statistics'
import { isEmpty } from '@/utils/utils'
export default {
  props: ['query', 'allColumns'],
  model: {
    prop: 'query',
    event: 'input'
  },
  data() {
    return {
      singlecolumns: [],
      statisticsDate: [],
      deptTreeid: [],
      deptTree: [],
      personIds: [],
      consultantList: [],
      queryForm: {},
      assetsIds: [],
      accountsList: [],
      isAllPerson: false, // 课程顾问是否全选
      isAllAssets: false // 账号是否全选
    }
  },
  watch: {
    statisticsDate: {
      handler(val) {
        if (!isEmpty(val)) {
          this.queryForm.startTime = val[0]
          this.queryForm.endTime = val[1]
        } else {
          this.queryForm.startTime = ''
          this.queryForm.endTime = ''
        }
      },
      immediate: true
    },
    deptTreeid: {
      handler(val) {
        if (!isEmpty(val)) {
          this.queryForm.deptIds = val.join(',')
        } else {
          this.queryForm.deptIds = ''
        }
      },
      immediate: true
    },
    personIds: {
      handler(val) {
        if (!isEmpty(val)) {
          this.queryForm.personIds = val.join(',')
        } else {
          this.queryForm.personIds = ''
        }
      },
      immediate: true
    },
    assetsIds: {
      handler(val) {
        if (!isEmpty(val)) {
          this.queryForm.assetsIds = val.join(',')
        } else {
          this.queryForm.assetsIds = ''
        }
      },
      immediate: true
    }
  },
  created() {
    this.statisticsDate = this.publicMethods.setDefaultDateTime(7)
    this.getDeptTree()
    this.getConsultantList()
    this.getAssetsList()
  },
  methods: {
    // 全选  0-课程顾问   1-账号
    handleAllCheck(type) {
      if (type == 0) {
        this.personIds = this.consultantList.map((item) => item.id)
        this.isAllPerson = !this.isAllPerson
      } else {
        this.assetsIds = this.accountsList.map((item) => item.id)
        this.isAllAssets = !this.isAllAssets
      }
    },
    // 取消全选  0-课程顾问   1-账号
    cancelAllCheck(type) {
      if (type == 0) {
        this.personIds = []
        this.isAllPerson = !this.isAllPerson
      } else {
        this.assetsIds = []
        this.isAllAssets = !this.isAllAssets
      }
    },
    getAssetsList() {
      const params = {
        personIds: this.personIds.join(',')
      }
      queryAssets(params).then((res) => {
        if (res.status == 200) {
          this.accountsList = res.data
        }
      })
      if (this.personIds == this.consultantList.map((item) => item.id)) {
        this.isAllPerson = true
      } else {
        this.isAllPerson = false
      }
    },
    changeAssets() {
      if (this.assetsIds == this.accountsList.map((item) => item.id)) {
        this.isAllAssets = true
      } else {
        this.isAllAssets = false
      }
    },
    emitData() {
      // 去掉默认显示的日期时间的时分秒 只取日期
      this.queryForm.startTime = this.queryForm.startTime.split(' ')[0]
      this.queryForm.endTime = this.queryForm.endTime.split(' ')[0]
      this.$emit('search', this.queryForm)
    },
    //获取部门树
    getDeptTree() {
      getDeptTree().then((res) => {
        this.deptTree = res.data
        this.deptTreeid = this.filterDeptId(res.data)
        this.emitData()
      })
    },
    filterDeptId(data) {
      let ids = []
      data.forEach((item) => {
        ids.push(item.id)
        if (item.childList && item.childList.length > 0) {
          ids = ids.concat(this.filterDeptId(item.childList))
        }
      })
      return ids
    },
    getConsultantList() {
      this.consultantList = []
      queryConsultantList({
        delFlag: 0,
        deptIds: this.deptTreeid.join(',')
      }).then((res) => {
        this.consultantList = res.data
        this.consultantList.push({
          id: '0',
          personRealName: '其他'
        })
      })
    },
    // 获取所选部门下的课程顾问
    deptChange() {
      this.getConsultantList()
    }
  }
}
</script>
<style lang="scss" scoped>
.select-btn {
  padding: 0 20px 10px;
  border-bottom: 1px solid #efefef;
}
</style>